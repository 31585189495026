import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { of, Subscription } from 'rxjs';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { GoogleAnalyticsService } from 'src/app/core/services/GoogleAnalytics/google-analytics.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService} from 'src/app/core/services/products/product.service';
import {ServiceService} from 'src/app/core/services/services/service.service';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

  workingHours: string | any;
  workingDay : string | any;
  isLoaded : boolean | any = false;
  isMobile = false;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  productCount : number  = 0;
  serviceCount: number = 0;
  subscription: Subscription = new Subscription()
  constructor(public merchantService: MerchantService,
    public productService: ProductService,
    public service: ServiceService,
    private breakPoint: BreakpointObserver,
    public categoryService: CategoryService,
    private cookieService: CookieService,
    private gaService: GoogleAnalyticsService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.subscription = this.breakPoint.observe([Breakpoints.Handset, Breakpoints.HandsetLandscape]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.HandsetPortrait] || state.breakpoints[Breakpoints.HandsetLandscape]) {
        this.isMobile = true;
      }
      else {
        this.isMobile = false;
      }
    })

    this.productService.getTotalProducts();


    this.categoryService.getCategories();

          
    this.service.getTotalServices();
    
    this.getWorkingHour();

    this.isLoaded = true;

    this.favIcon.href =  this.merchantService.merchant.favImage ? this.merchantService.merchant.favImage.small: "https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/favicon-big.png";
    this.gaService.eventEmitter( this.router.url, "VIEW",   this.cookieService.get('username'), 1);
  }

  callMobile(): void{
    this.gaService.eventEmitter("CONTACTNUMBER", "CLICK", this.cookieService.get('username'), 1);
  }

  getWorkingHour(): void{
    let day = new Date().getDay();
    if(day == 0)
    {
      this.workingDay = "Sunday";
      this.workingHours = this.merchantService.merchant.bizHours? this.merchantService.merchant.bizHours.sunday: "Off";
    }
    else if(day == 1)
    {
      this.workingDay = "Monday";
      this.workingHours = this.merchantService.merchant.bizHours? this.merchantService.merchant.bizHours.monday: "Off";
    }
    else if(day == 2)
    {
      this.workingDay = "Tuesday";
      this.workingHours = this.merchantService.merchant.bizHours? this.merchantService.merchant.bizHours.tuesday: "Off";
    }
    else if(day == 3)
    {
      this.workingDay = "Wednesday";
      this.workingHours = this.merchantService.merchant.bizHours? this.merchantService.merchant.bizHours.wednesday: "Off";
    }
    else if(day == 4)
    {
      this.workingDay = "Thursday";
      this.workingHours = this.merchantService.merchant.bizHours? this.merchantService.merchant.bizHours.thursday: "Off";
    }
    else if(day == 5)
    {
      this.workingDay = "Friday";
      this.workingHours = this.merchantService.merchant.bizHours? this.merchantService.merchant.bizHours.friday: "Off";
    }
    else{
      this.workingDay = "Saturday";
      this.workingHours = this.merchantService.merchant.bizHours? this.merchantService.merchant.bizHours.saturday: "Off";
    }
  }

}
