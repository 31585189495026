<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Banner -->
<div *ngIf="isMobile">
    <br/><br/><br/>
</div>


<app-background></app-background>


<!-- End Banner -->

<!-- Feature -->
<div class="feature-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-feature">
                    <i class="flaticon-doctor-1"></i>
                    <span>Find a doctor</span>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature active">
                    <a routerLink="/contact" title="Appointment">
                        <img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/icon/appointment.png" alt="Image">
                        <span>Request an appointment</span>
                    </a>

                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature">
                    <i class="flaticon-first-aid-kit"></i>
                    <span>Emergency case</span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Feature -->

<!-- Start Choose Us -->
<section class="choose-us-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>About us</span>
            <h2>Here is some information about us</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12" *ngIf="merchantService.merchant.featuredImage">
                <div class="choose-us-img">
                    <img src="{{merchantService.merchant.featuredImage.small}}" alt="{{merchantService.merchant.name}}" itemprop="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12" *ngIf="merchantService.merchant.featuredImage">
                <div class="choose-us-content">
                 <p [innerHTML]="merchantService.merchant.shortBio"></p>
                    <a itemprop="url" routerLink="/about" class="default-btn">About us</a>
                </div>
            </div>

            <div class="col-lg-12 col-md-12" *ngIf="!merchantService.merchant.featuredImage">
                <div class="choose-us-content">
                 <p [innerHTML]="merchantService.merchant.shortBio"></p>
                    <a itemprop="url" routerLink="/about" class="default-btn">About us</a>
                </div>
            </div>
        </div>
    </div>

    <div class="choose-us-shape"><img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/choose-us-shape.png" alt="Image"></div>
</section>
<!-- End Choose Us -->

<!-- Appointment -->
<section class="appointment-area">
    <div class="container">
        <div class="appointment-here-form">
            <form>
                <div class="row align-items-center">
                    <div class="col-lg-4 col-sm-6">
                        <h3>Book an appointment with us</h3>
                    </div>

                    <div class="col-lg-4 col-sm-6">

                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <a routerLink="/contact" class="default-btn" title="Contact now">Contact Now</a>

                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Appointment -->

<!-- Services -->
<section class="services-area pt-100 pb-70" *ngIf="service?.totalCount > 0">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="section-title text-align-left">
                    <span>Our services</span>
                    <h2>All the services that we provide to our patients in the field of treatment</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="section-btn">
                    <a routerLink="/services/1" class="default-btn">View all services</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let serviceItem of service.serviceList">
                <div class="single-services">
                    <div class="doctors-img">
                        <img *ngIf="serviceItem.primaryImage" src="{{serviceItem.primaryImage.small}}" alt="{{serviceItem.name}}">
                        <img *ngIf="!serviceItem.primaryImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{serviceItem.name}}">
                    </div>
                    <br/>
                    <h3><a routerLink="/{{serviceItem.slug}}" title="{{serviceItem.name}}" itemprop="url">{{serviceItem.name}}</a></h3>
                </div>
            </div>


        </div>
    </div>

    <div class="services-shape"><img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/services-shape.png" alt="Image"></div>
</section>
<!-- End Services -->

<!-- Testimonial -->
<section class="testimonial-area pb-100" *ngIf="testimonialService?.testimonials?.length > 0">
    <div class="container">
        <div class="section-title text-align-left">
            <span>Testimonial</span>
            <h2>What do our patients say?</h2>
        </div>

        <div class="testimonial-slider owl-theme owl-carousel">

            <div class="row align-items-center" *ngIf="testimonialService.testimonials[0]">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img *ngIf="testimonialService.testimonials[0].image" src="{{testimonialService.testimonials[0].image.small}}" alt="Image">
                        <div class="testimonial-shape">
                            <img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“{{testimonialService.testimonials[0].description}}”</p>
                        <h3>{{testimonialService.testimonials[0].name}}</h3>
                        <span>{{testimonialService.testimonials[0].title}}</span>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="testimonialService.testimonials[1]">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img *ngIf="testimonialService.testimonials[1].image" src="{{testimonialService.testimonials[1].image.small}}" alt="Image">
                        <div class="testimonial-shape">
                            <img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“{{testimonialService.testimonials[1].description}}”</p>
                        <h3>{{testimonialService.testimonials[1].name}}</h3>
                        <span>{{testimonialService.testimonials[1].title}}</span>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="testimonialService.testimonials[2]">
                <div class="col-lg-4 col-md-12">
                    <div class="testimonial-img">
                        <img *ngIf="testimonialService.testimonials[2].image" src="{{testimonialService.testimonials[2].image.small}}" alt="Image">
                        <div class="testimonial-shape">
                            <img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/testimonial/testimonial-shape.png" alt="Image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="testimonial-content">
                        <i class="flaticon-straight-quotes"></i>
                        <p>“{{testimonialService.testimonials[2].description}}”</p>
                        <h3>{{testimonialService.testimonials[2].name}}</h3>
                        <span>{{testimonialService.testimonials[2].title}}</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Testimonial -->


<!-- Portfolio -->

<section class="doctors-area pt-100 pb-70" style="background-color: #ffffff;" *ngIf="productService?.sideBarProducts?.length > 0">
    <div class="container">
        <div class="section-title">
            <span>Our Doctors</span>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let pdItem of productService.sideBarProducts">
                <div class="single-doctors">
                    <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
                        <div class="doctors-img">
                            <img *ngIf="!pdItem.primaryImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{pdItem.name}}">
                            <img *ngIf="pdItem.primaryImage" src="{{pdItem.primaryImage.small}}" alt="{{pdItem.name}}">

                        </div>
                        <div class="doctors-content">
                            <span>{{pdItem.category}}</span>
                            <h3 itemprop="name">{{pdItem.name}}</h3>
                        </div>
                    </a>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Portfolio -->



<!-- Blog -->
<section class="blog-area pt-100 pb-70" *ngIf="blogService?.Posts?.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="section-title text-align-left">
                    <span>Recent news</span>
                    <h2>All the recent news about us</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="section-btn">
                    <a routerLink="/blog/1" title="Our blog" itemprop="url" class="default-btn">View all news</a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let bgItem of blogService.Posts">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">
                            <img itemprop="image" *ngIf="!bgItem.imageModel" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{bgItem.title}}">
                            <img itemprop="image" *ngIf="bgItem.imageModel" src="{{bgItem.imageModel.medium}}" alt="{{bgItem.title}}">
                        </a>

                    </div>
                    <div class="blog-content">
                        <span>{{bgItem.createdOn | dateFormat}}</span>
                        <h3><a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">{{bgItem.title}}</a></h3>
                        <ul>
                            <li>By: <a routerLink="/">{{merchantService.merchant.name}}</a></li>
                            <li class="read"><a routerLink="/{{bgItem.slug}}" title="{{bgItem.title}}" itemprop="url">Read More <i class="flaticon-right-arrow"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->

<app-subscribers></app-subscribers>

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
