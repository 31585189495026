<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services Style One</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Services Style One</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Services -->
<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our services</span>
            <h2>All the services that I provide to my patients in the field of treatment</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-icon">
                        <img src="assets/img/icon/cardiology.png" alt="Image">
                    </div>
                    <h3><a routerLink="/services-details">Cardiology</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-icon">
                        <img src="assets/img/icon/neurology.png" alt="Image">
                    </div>
                    <h3><a routerLink="/services-details">Neurology</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-icon">
                        <img src="assets/img/icon/oncology.png" alt="Image">
                    </div>
                    <h3><a routerLink="/services-details">Cancer care</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-icon">
                        <img src="assets/img/icon/chemistry.png" alt="Image">
                    </div>
                    <h3><a routerLink="/services-details">Laboratory</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-icon">
                        <img src="assets/img/icon/lungs.png" alt="Image">
                    </div>
                    <h3><a routerLink="/services-details">Lung diseases</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-icon">
                        <img src="assets/img/icon/hand.png" alt="Image">
                    </div>
                    <h3><a routerLink="/services-details">Blood donor</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-icon">
                        <img src="assets/img/icon/chemistry.png" alt="Image">
                    </div>
                    <h3><a routerLink="/services-details">Home health</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-icon">
                        <img src="assets/img/icon/surgery.png" alt="Image">
                    </div>
                    <h3><a routerLink="/services-details">Robotic surgery</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="services-shape"><img src="assets/img/services-shape.png" alt="Image"></div>
</section>
<!-- End Services -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->