import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-footer-style-one',
  templateUrl: './footer-style-one.component.html',
  styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

  footerYear: string | any;
  constructor(public merchantService: MerchantService,
    public categoryService: CategoryService
    ) { }

  ngOnInit(): void {
    this.footerYear = new Date().getFullYear();
  }

}
