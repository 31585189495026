<!-- Header -->
<app-preloader *ngIf="!isLoaded"></app-preloader>
<header class="header-area header-area-two">

    <!-- Top Header -->
    <div class="top-header top-header-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-sm-4">
                    <ul class="header-left-content" *ngIf="merchantService.merchant.socialLinks">
                        <li><a href="{{merchantService.merchant.socialLinks.fbPageUrl}}" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="{{merchantService.merchant.socialLinks.pinterest}}" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="{{merchantService.merchant.socialLinks.twitterUsername}}" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="{{merchantService.merchant.socialLinks.linkedIn}}" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="{{merchantService.merchant.socialLinks.instagram}}" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                    <ul class="header-left-content" *ngIf="!merchantService.merchant.socialLinks">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>

                <div class="col-lg-9 col-sm-8">
                    <ul class="header-right-content">
                        <li>
                            <i class="flaticon-clock"></i>
                            {{this.workingDay}}
                            <span>{{this.workingHours}}</span>
                        </li>
                        <li>
                            <i class="flaticon-call"></i>
                            Call Now
                            <a itemprop="telephone" href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}">{{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Start Top Header -->
    
    <!-- Navbar -->
    <div class="navbar-area">
        <div class="mobile-nav">
            <div class="container">
                <a routerLink="/" class="logo">
                    <img style="object-fit: fill; max-width: 200px; max-height: 50px;" src="{{merchantService.merchant.logoImage.actual}}" alt="{{merchantService.merchant.name}}">
                </a>
            </div>
        </div>

        <div class="main-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md">
                    <a class="navbar-brand" routerLink="/">
                        <img style="object-fit: contain; max-width: 220px; max-height: 120px;" src="{{merchantService.merchant.logoImage.actual}}" alt="{{merchantService.merchant.name}}">
                       
                    </a>
                    
                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav m-auto">
                            <li class="nav-item">
                                <a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url" class="nav-link">Home </a>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Pages <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                                    
                                    <!-- <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Appointment</a></li> -->


                                    <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>
                                   
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">Departments <i class="bx bx-chevron-down"></i></a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item" *ngFor="let prodCat of categoryService?.productCategories">
                                        <a title="{{prodCat.name}}" itemprop="url" routerLink="/{{prodCat.slug.replace('category', 'department')}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                            {{prodCat.name}}</a>

                                            <ul class="dropdown-menu" *ngIf="prodCat?.childCategories?.length > 0">
                                                <li class="nav-item"  *ngFor="let catItem of prodCat.childCategories">
                                                    <a title="{{catItem.name}}" itemprop="url" routerLink="/{{catItem.slug.replace('category', 'department')}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{catItem.name}}</a>
                                                </li>
                                            </ul>
                                    </li>

                                    <li class="nav-item" >
                                        <a title="Services" routerLink="/services/1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                            View All Services</a>
                                    </li>
                                   
                                </ul>
                            </li>

                            <li class="nav-item">
                                <a routerLink="/doctors/1" title="Our Doctors" itemprop="url" class="nav-link">Doctors</a>
                            </li>

                            <li class="nav-item"><a title="Our Blog" itemprop="url" routerLink="/blog/1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                            <li class="nav-item"><a title="Contact us" itemprop="url" routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>
                        
                        <div class="others-option">
                            <div class="option-item">
                                <i class="search-btn bx bx-search"></i>
                                <i class="close-btn bx bx-x"></i>
                                <div class="search-overlay search-popup">
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" name="search" placeholder="Search" type="text">
                                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                          
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        
        <div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>
                
                <div class="container">
                    <div class="option-inner">
                        <div class="others-option justify-content-center d-flex align-items-center">
                            <div class="option-item">
                                <i class="search-btn bx bx-search"></i>
                                <i class="close-btn bx bx-x"></i>
                                <div class="search-overlay search-popup">
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" name="search" placeholder="Search" type="text">
                                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Navbar -->

</header>
<!-- End Header -->