<!-- Subscribe -->

<section class="subscribe-area pb-100">
    <br/><br/><br/>
    <div class="container">
        <div class="subscribe-bg">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="subscribe-content">
                        <img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/subscribe-img.png" alt="Image">
                        <h3>Sign up for newsletter</h3>
                        <span>Subscribe to the newsletter for all the latest updates</span>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <form class="newsletter-form" [formGroup]="subscriberForm" (ngSubmit)="subcribeUser()">
                        <input formControlName="email"  type="email" class="form-control" placeholder="Enter email address" name="email">
                        <button class="default-btn" type="submit">Subscribe</button>
                    </form>	
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Subscribe -->