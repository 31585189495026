<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Appointment</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Home</a></li>
                <li class="active">Appointment</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Appointment -->
<section class="appointment-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="patient-information">
                    <h3>Patient information</h3>

                    <div class="appointment-box mb-30">
                        <div class="appointment-form">
                            <form>
                                <div class="form-group">
                                    <label>Patient Name</label>
                                    <input type="text" class="form-control">
                                </div>

                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="text" class="form-control">
                                </div>

                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" class="form-control">
                                </div>

                                <div class="form-group">
                                    <label>Nationality</label>
                                    <select>
                                        <option value="">USA</option>	
                                        <option value="">London</option>
                                        <option value="">United Kingdom</option>
                                        <option value="">United States</option>
                                        <option value="">Canada</option>
                                        <option value="">Australia</option>
                                        <option value="">California </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label>Date Of Barth</label>
                                    <div class="input-group date" id="datetimepicker">
                                        <input type="text" class="form-control">
                                        <span class="input-group-addon"></span>
                                        <i class="bx bx-calendar"></i>
                                    </div>	
                                </div>

                                <div class="form-group">
                                    <label>Country Of Residence</label>
                                    <select>
                                        <option value="">USA</option>	
                                        <option value="">London</option>
                                        <option value="">United Kingdom</option>
                                        <option value="">United States</option>
                                        <option value="">Canada</option>
                                        <option value="">Australia</option>
                                        <option value="">California </option>
                                    </select>
                                </div>

                                <div class="checkbox-content">
                                    <div class="form-group">
                                        <input type="checkbox" id="chb1">
                                        <span>Male</span>
                                    </div>

                                    <div class="form-group">
                                        <input type="checkbox" id="chb2">
                                        <span>Female</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="patient-information">
                    <h3>Appointment information</h3>

                    <div class="appointment-box mb-30">
                        <div class="appointment-form">
                            <form>
                                <div class="form-group">
                                    <label>Specialty</label>
                                    <input type="text" class="form-control">
                                </div>

                                <div class="form-group">
                                    <label>Preferred Doctor</label>
                                    <select>
                                        <option value="">Dr.Anna Dew</option>
                                        <option value="">Dr.Juhon Dew</option>
                                        <option value="">Dr.Jon Smith Care</option>
                                        <option value="">Dr.Ana</option>
                                        <option value="">Dr.Kilva</option>
                                        <option value="">Dr.Zilka Smith</option>
                                        <option value="">Dr.Jilva Dew</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label>At The Following Hospital</label>
                                    <select>
                                        <option value="">K.G.T Canada</option>
                                        <option value="">P.K London</option>
                                        <option value="">C.T.U United Kingdom</option>
                                        <option value="">G.F United States</option>
                                        <option value="">P.G.F Canada</option>
                                        <option value="">M.K.J Australia</option>
                                        <option value="">I.C.U California</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label>Date Of Barth</label>
                                    <div class="input-group date" id="datetimepicker2">
                                        <input type="text" class="form-control">
                                        <span class="input-group-addon"></span>
                                        <i class="bx bx-calendar"></i>
                                    </div>	
                                </div>

                                <div class="form-group">
                                    <label>Comment</label>
                                    <textarea name="message" class="form-control" id="message" rows="4" spellcheck="false"></textarea>
                                </div>
                                
                                <button type="submit" class="default-btn">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Appointment -->

<!-- Subscribe -->
<app-subscribers></app-subscribers>
<!-- End Subscribe -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->