<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 itemprop="name">{{productService.productInfo.name}}</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Home</a></li>
                <li class="active">{{productService.productInfo.name}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Doctor Details -->
<section class="doctors-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="doctors-sidebar">
                    <div class="doctors-img">
                        <img itemprop="image" *ngIf="productService.productInfo.primaryImage" src="{{productService.productInfo.primaryImage.medium}}" alt="{{productService.productInfo.name}}">
                        <img itemprop="image" *ngIf="!productService.productInfo.primaryImage" src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/doctor-details/doctor-1.jpg" alt="{{productService.productInfo.name}}">
                        
                    </div>

                    <div class="availability">
                        <h3><i class="bx bx-time"></i> Availability</h3>

                        <ul class="open-day">
                            <li>
                                Sunday
                                <span *ngIf="merchantService.merchant.bizHours.sunday">{{merchantService.merchant.bizHours.sunday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours.sunday">Off</span>
                            </li>
                            <li>
                                Monday
                                <span *ngIf="merchantService.merchant.bizHours.monday">{{merchantService.merchant.bizHours.monday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours.monday">Off</span>
                            </li>
                            <li>
                                Tuesday
                                <span *ngIf="merchantService.merchant.bizHours.tuesday">{{merchantService.merchant.bizHours.tuesday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours.tuesday">Off</span>
                            </li>
                            <li>
                                Wednesday
                                <span *ngIf="merchantService.merchant.bizHours.wednesday">{{merchantService.merchant.bizHours.wednesday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours.wednesday">Off</span>
                            </li>
                            <li>
                                Thursday
                                <span *ngIf="merchantService.merchant.bizHours.thursday">{{merchantService.merchant.bizHours.thursday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours.thursday">Off</span>
                            </li>
                            <li>
                                Friday
                                <span *ngIf="merchantService.merchant.bizHours.friday">{{merchantService.merchant.bizHours.friday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours.friday">Off</span>
                            </li>
                            <li>
                                Saturday
                                <span *ngIf="merchantService.merchant.bizHours.saturday">{{merchantService.merchant.bizHours.saturday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours.saturday">Off</span>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="appointment-box">
                        <p>Book an appointment</p>

                        <div class="appointment-form">
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name">
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select Department</option>
                                        <option value="">Cardiology</option>
                                        <option value="">Neurology</option>
                                        <option value="">Cancer Care</option>
                                        <option value="">Laboratory</option>
                                        <option value="">Dental</option>
                                        <option value="">Neurology</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Service</option>
                                        <option value="">Services Style One</option>
                                        <option value="">Services Style Two</option>
                                        <option value="">Services Style Three</option>
                                        <option value="">Services Style Four</option>
                                        <option value="">Services Style Five</option>
                                        <option value="">Services Style Six</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Location</option>	
                                        <option value="">London</option>
                                        <option value="">United Kingdom</option>
                                        <option value="">United States</option>
                                        <option value="">Canada</option>
                                        <option value="">Australia</option>
                                        <option value="">California </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <select>
                                        <option value="">Select A Preferred Time</option>
                                        <option value="">8.00 AM To 9.00 AM</option>
                                        <option value="">9.00 AM To 10.00 AM</option>
                                        <option value="">11.00 AM To 12.00 AM</option>
                                        <option value="">12.00 AM To 1.00 PM</option>
                                        <option value="">1.00 PM To 2.00 PM</option>
                                        <option value="">2.00 PM To 3.00 pm</option>
                                        <option value="">3.00 PM To 4.00 PM</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <div class="input-group date" id="datetimepicker">
                                        <input type="text" class="form-control" placeholder="07/09/2020">
                                        <span class="input-group-addon"></span>
                                        <i class="bx bx-calendar"></i>
                                    </div>	
                                </div>
                        
                                <button type="submit" class="default-btn">Book appointment</button>
                            </form>
                        </div>
                    </div> -->
                </div>
            </div>
        
            <div class="col-lg-8 col-md-12">
                <div class="doctors-details mb-30">
                    <div class="doctors-history">
                        <h2 itemprop="name">{{productService.productInfo.name}}</h2>
                        <span>{{productService.productInfo.category}}</span>
                        <p itemprop="description"  style="text-align: justify;">{{productService.productInfo.shortBio}}</p>
                        <p itemprop="description" style="text-align: justify;" [innerHTML]="productService.productInfo.description | keepHtml"></p>
                        
                    </div>
                </div>

                
            </div>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70" style="background-color: #ffffff;">
    <div class="container">
        <div class="section-title">
            <span>Our Doctors</span>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let pdItem of productService.sideBarProducts">
                <div class="single-doctors">
                    <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
                        <div class="doctors-img">
                            <img *ngIf="!pdItem.primaryImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{pdItem.name}}">
                            <img *ngIf="pdItem.primaryImage" src="{{pdItem.primaryImage.small}}" alt="{{pdItem.name}}">
                           
                        </div>
                        <div class="doctors-content">
                            <span>{{pdItem.category}}</span>
                            <h3 itemprop="name">{{pdItem.name}}</h3>
                        </div>
                    </a>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Doctor Details -->
<app-subscribers></app-subscribers>
<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->