import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { blogPost } from 'src/app/core/models/blog/blog.models';
import { message } from 'src/app/core/models/ROI/message.models';
import { BlogService } from 'src/app/core/services/blogs/blog.service';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  public routerUrl: string | any;
  public contactForm: FormGroup;
  constructor(private route: ActivatedRoute,
    public merchantService: MerchantService,
    private titleService: Title,
    private metaService: Meta,
    private cookieService: CookieService,
    private http: HttpClient,
    private router: Router,
    public categoryService: CategoryService,
    private toastr: ToastrService,
     public blogService: BlogService) { 

        // force route reload whenever params change;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.contactForm = new FormGroup({
        email: new FormControl(null, Validators.required),
        firstName: new FormControl(null, Validators.required),
        lastName: new FormControl(null, null),
        phone: new FormControl(null, Validators.required),
        subject: new FormControl(null, null),
        messageText: new FormControl(null, Validators.required)
      });

     }
     ngOnChanges(): void{
      this.setSEOData();

     }
 ngOnInit(): void {
   const routeParams = this.route.snapshot.paramMap;
   const index = Number(routeParams.get('index'));
   
   this.blogService.getPosts(0, 3);
   this.blogService.getPostInfoObserver(index).pipe(
    take(1),
    catchError(err => {
      return of({ err: true, message: err.error.error_description })
    })
  ).subscribe((data: blogPost | any) => {
    this.blogService.Article = data;
    this.setSEOData();
    this.routerUrl = this.merchantService.merchant.url + "/"+ this.blogService.Article.slug;
  });
 }

 contactUs():void{
  const {email, firstName, lastName, phone, subject, messageText} = this.contactForm.value;

  if(firstName == null || firstName == "")
  {
    this.toastr.error("First name is mandatory");
  }
  else if(email == null || email == "")
  {
    this.toastr.error("Email is mandatory");
  }
  else if(phone == null || phone == "")
  {
    this.toastr.error("Mobile number is mandatory");
  }
  else if(messageText == null || messageText == "")
  {
    this.toastr.error("Message is mandatory");
  }
  else{

    let usernameDetails = this.cookieService.get("username");
    let messageModel: message = {
      websiteName: usernameDetails,
      contactName: firstName + ',' + lastName,
      email : email,
      from: phone,
      message : messageText,
      productId :""
    };

    const contactEndpoint = environment.apiUrl + "/api/Internal/messages";
    
    this.http.post(contactEndpoint, messageModel, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if(d == null)
      {
        this.toastr.success("Thank you for contacting us, we will get back to you shortly");
      }
      else if (d.err) {
        this.toastr.error("There is an issue at our end, please contact us at directly by calling us or sending us an email");
      }
    
    });

  }

 
}


 setSEOData(): void {
   const merchantDetails = this.merchantService.merchant;
  

   let aboutDescription = `${this.blogService.Article.title}, Blog of ${merchantDetails.name}, Blog of ${merchantDetails.name} in ${merchantDetails.locality}, Blog of  ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

   this.titleService.setTitle(aboutDescription);

   this.metaService.addTag({name: 'name', content: aboutDescription});
   this.metaService.addTag({name: 'description', content: aboutDescription});
   this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
   this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
   this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


   this.metaService.addTag({name: 'twitter:card', content: 'product'});
   this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
   this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
   this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


   //OG GRAPH DATA
   this.metaService.addTag({name: 'og:type', content: 'article'});
   this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
   this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/blog/1"});
   this.metaService.addTag({name: 'og:description', content: aboutDescription});
   this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
 }
}
