<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Three Column</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Blog Three Column</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start log Column Three Area -->
<div class="blog-column-three-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-4.jpg" alt="Image">
                        </a>

                        <div class="date">
                            <ul>
                                <li>07</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>

                    <div class="blog-content">
                        <span>General news</span>

                        <h3>
                            <a routerLink="/blog-details">
                                It might be time to seek a medical your muscle and joint pain
                            </a>
                        </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>

                        <ul>
                            <li>
                                By:
                                <a routerLink="/">Curtis Warren</a>
                            </li>
                            <li class="read">
                                <a routerLink="/blog-details">
                                    Read More
                                    <i class="flaticon-right-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-5.jpg" alt="Image">
                        </a>

                        <div class="date">
                            <ul>
                                <li>08</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>

                    <div class="blog-content">
                        <span>General news</span>

                        <h3>
                            <a routerLink="/blog-details">
                                The reassurance of a safe pregnancy through the birth of your baby
                            </a>
                        </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>

                        <ul>
                            <li>
                                By:
                                <a routerLink="/">James Lopez</a>
                            </li>
                            <li class="read">
                                <a routerLink="/blog-details">
                                    Read More
                                    <i class="flaticon-right-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-6.jpg" alt="Image">
                        </a>

                        <div class="date">
                            <ul>
                                <li>10</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>

                    <div class="blog-content">
                        <span>General news</span>

                        <h3>
                            <a routerLink="/blog-details">
                                Read up on different types of fevers that may require a doctor
                            </a>
                        </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>

                        <ul>
                            <li>
                                By:
                                <a routerLink="/">Milton Baines</a>
                            </li>
                            <li class="read">
                                <a routerLink="/blog-details">
                                    Read More
                                    <i class="flaticon-right-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-7.jpg" alt="Image">
                        </a>

                        <div class="date">
                            <ul>
                                <li>11</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>

                    <div class="blog-content">
                        <span>General News</span>

                        <h3>
                            <a routerLink="/blog-details">
                                What to expect when your child starts talking doctor
                            </a>
                        </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>

                        <ul>
                            <li>
                                By:
                                <a routerLink="/">Adam Smith</a>
                            </li>
                            <li class="read">
                                <a routerLink="/blog-details">
                                    Read More
                                    <i class="flaticon-right-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-8.jpg" alt="Image">
                        </a>

                        <div class="date">
                            <ul>
                                <li>08</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>

                    <div class="blog-content">
                        <span>General news</span>

                        <h3>
                            <a routerLink="/blog-details">
                                The reassurance of a safe pregnancy through the birth of your baby
                            </a>
                        </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>

                        <ul>
                            <li>
                                By:
                                <a routerLink="/">James Lopez</a>
                            </li>
                            <li class="read">
                                <a routerLink="/blog-details">
                                    Read More
                                    <i class="flaticon-right-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-9.jpg" alt="Image">
                        </a>

                        <div class="date">
                            <ul>
                                <li>09</li>
                                <li>Sep</li>
                            </ul>
                        </div>
                    </div>

                    <div class="blog-content">
                        <span>General news</span>

                        <h3>
                            <a routerLink="/blog-details">
                                Read up on different types of fevers that may require a doctor
                            </a>
                        </h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna</p>

                        <ul>
                            <li>
                                By:
                                <a routerLink="/">Milton Baines</a>
                            </li>
                            <li class="read">
                                <a routerLink="/blog-details">
                                    Read More
                                    <i class="flaticon-right-arrow"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/" class="prev page-numbers">
                        <i class="bx bx-chevron-left"></i>
                    </a>

                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/" class="page-numbers">2</a>
                    <a routerLink="/" class="page-numbers">3</a>
                    
                    <a routerLink="/" class="next page-numbers">
                        <i class="bx bx-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End log Column Three Area -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->