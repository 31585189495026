import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(public merchantService: MerchantService,
    public productService: ProductService,
    private titleService: Title, private metaService:Meta) { }

  ngOnInit(): void {
   
    this.setSEOData();
    this.productService.getProductsForSidebar();
  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   

    let aboutDescription = `About ${merchantDetails.name}, About ${merchantDetails.name} in ${merchantDetails.locality}, About ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/about"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})

  }

}
