<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Services</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Home</a></li>
                <li class="active">Our Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->



<section class="services-area pt-100 pb-70" *ngIf="productService?.serviceList?.length > 0">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="section-title text-align-left">
                    <span>Our services</span>
                    <h2>All the services that we provide to our patients in the field of treatment</h2>
                </div>
            </div>

           
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let serviceItem of productService.serviceList">
                <div class="single-services">
                    <div class="doctors-img">
                        <img *ngIf="serviceItem.primaryImage" src="{{serviceItem.primaryImage.small}}" alt="{{serviceItem.name}}">
                        <img *ngIf="!serviceItem.primaryImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{serviceItem.name}}">
                    </div>
                    <br/>
                    <h3><a routerLink="/{{serviceItem.slug}}" title="{{serviceItem.name}}" itemprop="url">{{serviceItem.name}}</a></h3>
                </div>
            </div>

            <br/>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a itemprop="url" title="Previous page" routerLink="{{productService.prevLink}}"  class="prev page-numbers">
                        <i class="bx bx-chevron-left"></i>
                    </a>

                    <a itemprop="url" title="Next page" routerLink="{{productService.nextLink}}" class="next page-numbers">
                        <i class="bx bx-chevron-right"></i>
                    </a>
                </div>
            </div>

           
        </div>
    </div>

    <div class="services-shape"><img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/services-shape.png" alt="Image"></div>
</section>

<app-subscribers></app-subscribers>
<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->