import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/core/services/blogs/blog.service';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-blog-one-column',
  templateUrl: './blog-one-column.component.html',
  styleUrls: ['./blog-one-column.component.scss']
})
export class BlogOneColumnComponent implements OnInit {

  constructor(private route: ActivatedRoute, 
    private router: Router,
    public merchantService: MerchantService,
    public blogService: BlogService,
    private titleService: Title,
    private metaService: Meta,
    public categoryService: CategoryService
    ) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    const index = Number(routeParams.get('index'));

    
    this.blogService.getPosts(index-1, 6);
    this.setSEOData();
  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   

    let aboutDescription = `Blog of ${merchantDetails.name}, Blog of ${merchantDetails.name} in ${merchantDetails.locality}, Blog of  ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/blog/1"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

}
