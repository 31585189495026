import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  constructor(public merchantService: MerchantService,
    public categoryService: CategoryService) { }

  ngOnInit(): void {
    this.categoryService.getCategories();
  }

}
