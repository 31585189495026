import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ImageSliderModel } from '../../models/merchant/bgImage.models';
import { product } from '../../models/products/product.models';
import { serviceModel } from '../../models/services/service.models';


@Injectable({
    providedIn: 'root'
})
export class ServiceService {
    totalCount: number | any;
    serviceList: Array<serviceModel> | any;
    serviceInfo: serviceModel | any;
    serviceImageSlider: ImageSliderModel[] | any;
    count: number | any;
    nextLink: string | any;
    prevLink: string | any;

    imageDataUpdated = new Subject();
    constructor(private http: HttpClient, private cookieService: CookieService) { }

    getServices(skip: number, limit: number) {
        let username = this.cookieService.get("username");
        if (username && username !== 'undefined') {
            const productsListEndPoint = environment.apiUrl + '/api/Services/' + username + "/list?skip=" + skip + "&limit=" + limit;
            this.http.get<serviceModel[]>(productsListEndPoint).pipe(
                take(1),
                catchError(err => {
                    return of({ err: true, message: err.error.error_description })
                })
            ).subscribe((data: serviceModel[] | any) => {
                this.serviceList = data;
                this.count = this.serviceList.length;
                this.pagination(skip + 1);
            });
        }
    }


    getTotalServices() {
        let username = this.cookieService.get("username");
        if (username && username !== 'undefined') {
            const totalServicesEndPoint = environment.apiUrl + '/api/Services/' + username + "/count";
            this.http.get<number>(totalServicesEndPoint).pipe(
                take(1),
                catchError(err => {
                    return of({ err: true, message: err.error.error_description })
                })
            ).subscribe((data: number | any) => {
                this.totalCount = data;
            });
        }
    }

    getServiceInfoObserver(index: number): Observable<any> {
        const username = this.cookieService.get("username");
        if (username && username !== 'undefined') {
            const productDetailsEndPoint = environment.apiUrl + '/api/Services/' + username + "/" + index;
            return this.http.get<serviceModel[]>(productDetailsEndPoint);

        }
        return of(null);


    }

    getServiceInfo(index: number) {
        let username = this.cookieService.get("username");
        if (username && username !== 'undefined') {
            const productDetailsEndPoint = environment.apiUrl + '/api/Services/' + username + "/" + index;
            this.http.get<serviceModel[]>(productDetailsEndPoint).pipe(
                take(1),
                catchError(err => {
                    return of({ err: true, message: err.error.error_description })
                })
            ).subscribe((data: serviceModel | any) => {
                this.serviceInfo = data;
                this.getImageSlider(data);
                this.imageDataUpdated.next(this.serviceImageSlider);
            });
        }




    }

    pagination(index: number) {
        this.nextLink = "/services/" + index;
        this.prevLink = "/services/" + (index - 1);
        if (index <= 1) {
            this.prevLink = "/services/1";
            if (this.count < 9) {
                this.nextLink = "/services/1";
            }
            else {
                this.nextLink = "/services/" + (index + 1);
            }
        }
        else {
            this.prevLink = "/services/" + (index - 1);
            if (this.count < 9) {
                this.nextLink = "/services/" + (index);
            }
            else {
                this.nextLink = "/services/" + (index + 1);
            }
        }

    }

    getImageSlider(serviceItem: serviceModel) {
        const tempArray: ImageSliderModel[] = [];
        if (serviceItem.secondaryImageModel != null) {
            serviceItem.secondaryImageModel.forEach(element => {
                const imageModel: ImageSliderModel = {
                    alt: serviceItem.name,
                    image: element.large,
                    thumbImage: element.small,
                    title: ''
                };
                tempArray.push(imageModel);
            });
        }

        this.serviceImageSlider = [...tempArray];

    }
}
