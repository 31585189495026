import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  username: string | any;
  constructor(private http: HttpClient) { }

  getUsernameObserver(urlString: string): Observable<any> {
    const getDomainUrl = environment.apiUrl + '/api/Internal/domains';
    const body = { url: urlString };
    const requestOptions: any = {
      responseType: 'text'
    };
    return this.http.post<any>(getDomainUrl, body, requestOptions);
  }
}
