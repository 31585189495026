import { Injectable, isDevMode } from '@angular/core';


declare let gtag:Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  public eventEmitter( 
    eventCategory: string, 
    eventAction: string, 
    eventLabel: string = null,  
    eventValue: number = null ){ 
      
      if(isDevMode)
      {

      }
      else{
        gtag('event', eventAction, { 
          'event_category': eventCategory, 
          'event_label': eventLabel, 
          'value': eventValue
        });
      }
        
    }
}
