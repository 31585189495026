import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { category, productCategories } from '../../models/categories/category.models';
import { product } from '../../models/products/product.models';
import { serviceModel } from '../../models/services/service.models';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  categories: Array<category> = [];
  productList: Array<product> = [];
  serviceList: Array<serviceModel> = [];
  productCategories: Array<productCategories> = [];
  constructor(private cookieService: CookieService,
    private http: HttpClient
    ) { 

    }

    getCategories()
    {
      let username = this.cookieService.get("username");
      if(username && username !== 'undefined')
      {
          const getCategoriesEndPoint =  environment.apiUrl + '/api/Categories/tree/'+ username;
          this.http.get<category[]>(getCategoriesEndPoint).pipe(
            take(1),
            catchError(err => {
              return of({ err: true, message: err.error.error_description })
            })
          ).subscribe((data : category[] |any) => {
            this.categories = data;
            this.getProductCategories(this.categories);
          });
      }
    }


    getProductCategories(categories: category[])
    {
       if(categories != null)
       {

        let parentCategories = categories.filter(s=> s.isParent == true || s.parentCategory == "NA");
        if(parentCategories != null)
        {
            parentCategories.forEach(elem=> {

              let prodCat: productCategories = {
                name: elem.name,
                username: elem.username,
                isParent: elem.isParent,
                parentCategory: elem.parentCategory,
                _id: elem._id,
                parentCategoryId: elem.parentCategoryId,
                slug: elem.slug,
                childCategories: elem.childCategories
              };
             
              let elemFound = this.productCategories.filter(q=> q._id == prodCat._id);
             
              if(elemFound?.length == 0)
              {
                this.productCategories.push(prodCat);
              }
            });
        }
       }
    }

    getChildCategories(categoryName: string, categoryList: category[]): category[] | any{
      let childCategories = categoryList.filter(s=> s.isParent == false && s.parentCategory != null && s.parentCategory.toLowerCase() == categoryName.toLowerCase());
      return childCategories;
    }


    getProductsForCategory(categoryId: string){
      let username = this.cookieService.get("username");
      if(username && username !== 'undefined')
      {
          const getProductForCategoriesEndPoint =  environment.apiUrl + '/api/Categories/'+ username + "/Products/"+ categoryId;
          this.http.get<product[]>(getProductForCategoriesEndPoint).pipe(
            take(1),
            catchError(err => {
              return of({ err: true, message: err.error.error_description })
            })
          ).subscribe((data : product[] |any) => {
            this.productList = data;
          });
      }
    }



    getServicesForCategory(categoryId: string){
      let username = this.cookieService.get("username");
      if(username && username !== 'undefined')
      {
          const getProductForCategoriesEndPoint =  environment.apiUrl + '/api/Categories/'+ username + "/Services/"+ categoryId;
          this.http.get<serviceModel[]>(getProductForCategoriesEndPoint).pipe(
            take(1),
            catchError(err => {
              return of({ err: true, message: err.error.error_description })
            })
          ).subscribe((data : serviceModel[] |any) => {
            this.serviceList = data;
          });
      }
    }

}
