import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {SubscriberService} from 'src/app/core/services/subscriber/subscriber.service';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent implements OnInit {

  public subscriberForm: FormGroup;
  constructor(private subscriberService: SubscriberService) { 
    this.subscriberForm = new FormGroup({
      email: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
  }

  subcribeUser(): void {
    const { email } = this.subscriberForm.value;
    this.subscriberService.subscribeUser(email);
  }

}
