import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { environment } from 'src/environments/environment';
import {message} from 'src/app/core/models/ROI/message.models';

@Component({
  selector: 'app-contact',
  //providers: [MarkerManager, GoogleMapsAPIWrapper],
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;
  lat = 13;
  lng = 80;
  constructor(public merchantService: MerchantService,
    private titleService: Title,
    private metaService: Meta,
    private http: HttpClient,
    private toastr: ToastrService,
    private cookieService: CookieService) { 
      this.contactForm = new FormGroup({
        email: new FormControl(null, Validators.required),
        firstName: new FormControl(null, Validators.required),
        lastName: new FormControl(null, null),
        phone: new FormControl(null, Validators.required),
        messageText: new FormControl(null, Validators.required)
      });
    }

  ngOnInit(): void {
    this.setSEOData();
    this.lat = this.merchantService.merchant.lat;
    this.lng = this.merchantService.merchant.lng;
  }

  contactUs(): void {
    const { email, firstName, lastName, phone, messageText } = this.contactForm.value;

    if (firstName == null || firstName == "") {
      this.toastr.error("First name is mandatory");
    }
    else if (email == null || email == "") {
      this.toastr.error("Email is mandatory");
    }
    else if (phone == null || phone == "") {
      this.toastr.error("Mobile number is mandatory");
    }
    else if (messageText == null || messageText == "") {
      this.toastr.error("Message is mandatory");
    }
    else {

      let usernameDetails = this.cookieService.get("username");
      let messageModel: message = {
        websiteName: usernameDetails,
        contactName: firstName + ',' + lastName,
        email: email,
        from: phone,
        message: messageText,
        productId: ""
      };

      const contactEndpoint = environment.apiUrl + "/api/Internal/messages";

      this.http.post(contactEndpoint, messageModel, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if (d == null) {
          this.toastr.success("Thank you for contacting us");
        }
        else if (d.err) {
          this.toastr.error("There is an issue at our end, please contact us at directly by calling us or sending us an email");
        }

      });

    }


  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;


    let aboutDescription = `Contact ${merchantDetails.name}, Contact ${merchantDetails.name} in ${merchantDetails.locality}, Contact ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({ name: 'name', content: aboutDescription });
    this.metaService.addTag({ name: 'description', content: aboutDescription });
    this.metaService.addTag({ name: 'image', content: merchantDetails.logoImage.small })
    this.metaService.addTag({ name: 'logo', content: merchantDetails.logoImage.small })
    this.metaService.addTag({ name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList })


    this.metaService.addTag({ name: 'twitter:card', content: 'product' });
    this.metaService.addTag({ name: 'twitter:title', content: aboutDescription });
    this.metaService.addTag({ name: 'twitter:description', content: aboutDescription });
    this.metaService.addTag({ name: 'twitter:image', content: merchantDetails.logoImage.small })


    //OG GRAPH DATA
    this.metaService.addTag({ name: 'og:type', content: 'article' });
    this.metaService.addTag({ name: 'og:site_name', content: merchantDetails.name });
    this.metaService.addTag({ name: 'og:url', content: merchantDetails.url + "/contact" });
    this.metaService.addTag({ name: 'og:description', content: aboutDescription });
    this.metaService.addTag({ name: 'og:image', content: merchantDetails.logoImage.small })
  }

}
