<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/" itemprop="url" title="{{merchantService?.merchant?.name}}">Home</a></li>
                <li class="active">About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Choose Us -->
<section class="choose-us-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>About us</span>
         </div>

        <div class="row align-items-center">
           

            <div class="col-lg-12 col-md-12">
                <div class="choose-us-content">
                     <p style="text-align: justify;" itemprop="description" [innerHTML]="merchantService?.merchant?.description | keepHtml"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="choose-us-shape"><img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/choose-us-shape.png" alt="Image"></div>
</section>
<!-- End Choose Us -->



<!-- Choose Us -->
<section class="choose-us-area-two pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="choose-us-content-two">
                    <span class="top-title">Why choose us</span>
                    <p style="text-align: justify;">{{merchantService.merchant.shortBio}}</p>
                  
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="choose-us-img-two">
                    <img src="https://themedata.s3.ap-south-1.amazonaws.com/vuci-hospital/assets/img/choose-us-img.png" alt="Image">

                    <div class="address-and-week">
                        <p>You can reach us using the information mentioned below</p>
                        <div class="address-week">
                            <ul class="address">
                                <li>
                                    <i class="flaticon-telephone-1"></i>
                                    <span>Call: </span>
                                    <a title="Call us" itemprop="url" href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}">{{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                                </li>
                                <li>
                                    <i class="flaticon-arroba"></i>
                                    <span>Email: </span>
                                    <a itle="Call us" itemprop="url" href="mailto:{{merchantService.merchant.email}}">{{merchantService.merchant.email}}</a>
                                </li>
                                <li>
                                    <i class="flaticon-address-1"></i>
                                    <span>Address: </span>
                                    {{merchantService.merchant.streetAddress}} {{merchantService.merchant.locality}} {{merchantService.merchant.city}} {{merchantService.merchant.country}} {{merchantService.merchant.pinCode}}
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Choose Us -->

<br/><br/>

<!-- Appointment -->
<section class="appointment-area">
    <div class="container">
        <div class="appointment-here-form">
            <form>
                <div class="row align-items-center">
                    <div class="col-lg-4 col-sm-6">
                        <h3>Book an appointment</h3>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                    </div>
                    
                    <div class="col-lg-4 col-sm-6">
                        <a routerLink="/contact" title="Book an appointment"  class="default-btn">Contact Now</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Appointment -->

<!-- Portfolio -->

<section class="doctors-area pt-100 pb-70" *ngIf="productService?.sideBarProducts?.length > 0">
    <div class="container">
        <div class="section-title">
            <span>Our Doctors</span>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let pdItem of productService?.sideBarProducts">
                <div class="single-doctors">
                    <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
                        <div class="doctors-img">
                            <img *ngIf="!pdItem.primaryImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{pdItem.name}}">
                            <img *ngIf="pdItem.primaryImage" src="{{pdItem.primaryImage.small}}" alt="{{pdItem.name}}">
                           
                        </div>
                        <div class="doctors-content">
                            <span>{{pdItem.category}}</span>
                            <h3 itemprop="name">{{pdItem.name}}</h3>
                        </div>
                    </a>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Portfolio -->


<app-subscribers></app-subscribers>
<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->