import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { backgroundImage, ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';
import { ServiceService } from 'src/app/core/services/services/service.service';
import {TestimonialsService} from 'src/app/core/services/testimony/testimonials.service';
import {BlogService} from 'src/app/core/services/blogs/blog.service';
import { Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  isMobile = false;
  sliderImages: ImageSliderModel[] = [];
  sliderImage: any;
  subscription: Subscription = new Subscription()
  constructor(public merchantService: MerchantService,
    private titleService: Title,
    public service: ServiceService,
    public testimonialService: TestimonialsService,
    public productService: ProductService,
    private breakPoint: BreakpointObserver,
    public blogService: BlogService,
    private metaService: Meta) { }

  ngOnInit(): void {
    this.subscription = this.breakPoint.observe([Breakpoints.Handset, Breakpoints.HandsetLandscape]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.HandsetPortrait] || state.breakpoints[Breakpoints.HandsetLandscape]) {
        this.isMobile = true;
      }
      else {
        this.isMobile = false;
      }
    })
    this.setSEOData();
    this.getBackgroundImages();
    this.service.getServices(0, 3);
    this.productService.getProductsForSidebar();
    this.blogService.getPosts(0, 3);
    this.testimonialService.getTestimonails();

  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   

    let aboutDescription = `${merchantDetails.name}, ${merchantDetails.name} in ${merchantDetails.locality}, ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/about"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

  getBackgroundImages(): void {
    const title = this.merchantService.merchant.name;
    this.merchantService.getBackgroundImage().pipe(
      take(1)
    ).subscribe((backgroundImagesList: any) => {
      const newArray: ImageSliderModel[] = [];
      if (backgroundImagesList?.length > 0) {
        backgroundImagesList.forEach((element: any) => {
          const sliderImage: ImageSliderModel = {
            alt: title,
            image: element.imageModel.large,
            thumbImage: element.imageModel.large,
            title: ''
          };
          newArray.push(sliderImage);
        });
        this.sliderImages = [...newArray];
      }
      else {
        this.sliderImages = [...newArray];
      }
    });
  }

}
