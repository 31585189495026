import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit, OnDestroy  {

 
  subs = new Subscription();
  constructor(private merchant: MerchantService) { }


  ngOnInit(): void {
    this.subs = this.merchant.merchantDataEvt.subscribe(d => {
      this.addScripts();
    })

  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  addScripts(): void {
    const options = {
      "enabled": true,
      "chatButtonSetting": {
        "backgroundColor": "#13316e",
        "ctaText": "",
        "borderRadius": "25",
        "marginLeft": "0",
        "marginBottom": "50",
        "marginRight": "50",
        "position": "right"
      },
      "brandSetting": {
        "brandName": this.merchant.getMerchantData().name,
        "brandSubTitle": "Typically replies within a day",
        "brandImg": this.merchant.getMerchantData().logoImage.small,
        "welcomeText": "Hi, there!\nHow can I help you?",
        "messageText": `Hello, I have a question about ${window.location.href}`,
        "backgroundColor": "#13316e",
        "ctaText": "Start Chat",
        "borderRadius": "50",
        "autoShow": false,
        "phoneNumber": this.merchant.getMerchantData().countryCode + this.merchant.getMerchantData().mobileNumber
      }
    };
    const url = 'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?47713';
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = function () {
      const windowObj = window as any;
      windowObj.CreateWhatsappChatWidget(options);
    };
    var x = document.getElementsByTagName('script')[0] as any;
    x.parentNode.insertBefore(node, x);
  }
}
