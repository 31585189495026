import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { cartModel } from 'src/app/core/models/cart/cart.models';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { product } from 'src/app/core/models/products/product.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';

@Component({
  selector: 'app-doctor-details',
  templateUrl: './doctor-details.component.html',
  styleUrls: ['./doctor-details.component.scss']
})
export class DoctorDetailsComponent implements OnInit {

  
  cartArray: Array<cartModel> | any;

  imageData: ImageSliderModel[] = [];
  constructor(private route: ActivatedRoute, 
    public merchantService: MerchantService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    public productService: ProductService) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {

    const routeParams = this.route.snapshot.paramMap;
    const index = Number(routeParams.get('index'));
    
    
    this.productService.getProductsForSidebar();
    this.productService.getProductInfoObserver(index).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((data: product | any) => {
      this.productService.productInfo = data;
      const tempArray: ImageSliderModel[] = [];
      for (let index = 0; index < data.secondaryImageModel?.length; index++) {
        const element = data.secondaryImageModel[index];
       tempArray.push({
          alt: data.name,
          image: element.large,
          thumbImage: element.large,
          title: ''
        })
      }
      this.imageData = [...tempArray];
      this.setSEOData();
    });
  }

  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   

    let aboutDescription = `${this.productService.productInfo.name}, ${this.productService.productInfo.category}, Doctors of ${merchantDetails.name}, Doctors of ${merchantDetails.name} in ${merchantDetails.locality}, Doctors of ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/albums/1"});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

}
