<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Pricing</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Pricing -->
<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing plans</span>
            <h2>We have different types of pricing plans to meet patient needs and budget</h2>
        </div>
        
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <h3>Starter</h3>
                    <h2><Sub>$</Sub>79</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>

                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal fees</li>
                        <li>Routine checkup</li>
                    </ul>

                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing active">
                    <h3>Basic</h3>
                    <h2><Sub>$</Sub>99</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>

                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal fees</li>
                        <li>Routine checkup</li>
                    </ul>

                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <h3>Premium</h3>
                    <h2><Sub>$</Sub>129</h2>
                    <span>Per Mouth</span>
                    <h4>Features</h4>

                    <ul>
                        <li>Doctors charge fees</li>
                        <li>Emergency room</li>
                        <li>Hospital services</li>
                        <li>Primary care</li>
                        <li>Medico legal fees</li>
                        <li>Routine checkup</li>
                    </ul>

                    <a routerLink="/" class="default-btn">Make payment</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->

<!-- Online Price -->
<div class="online-price-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 pr-0">
                <div class="online-price-list">
                    <ul>
                        <li>
                            Online doctors consultation	
                            <span class="one">$50.00</span> 
                            <span class="two">30 Minutes</span>
                        </li>

                        <li>
                            Online consultation extension	
                            <span class="one">$40.00</span> 
                            <span class="two">30 Minutes</span>
                        </li>

                        <li>
                            Prescription processing fee
                            <span class="one">$10.00</span> 
                            <span class="two">Per trescription</span>
                        </li>

                        <li>
                            One time consultation	
                            <span class="one">$40.00</span> 
                            <span class="two">15 Minutes</span>
                        </li>

                        <li>
                            Prescription processing	
                            <span class="one">FREE</span> 
                            <span class="two">Per treatment</span>
                        </li>

                        <li>
                            Online doctors assessment	
                            <span class="one">FREE</span> 
                            <span class="two">Per treatment</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 pl-0">
                <div class="online-price-img"></div>
            </div>
        </div>
    </div>
</div>
<!-- End Online Price -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->