import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { serviceModel } from 'src/app/core/models/services/service.models';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ServiceService } from 'src/app/core/services/services/service.service';

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  imageData: ImageSliderModel[] = [];
  constructor(private route: ActivatedRoute, 
    public merchantService: MerchantService,
    private titleService: Title,
    private metaService: Meta,
    public categoryService: CategoryService,
    public productService: ServiceService, private router: Router) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     }

  ngOnInit(): void {
  
    const routeParams = this.route.snapshot.paramMap;
    const index = Number(routeParams.get('index'));
    
    this.productService.getServices(0, 3);

    this.productService.getServiceInfoObserver(index).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((data: serviceModel | any) => {
      this.productService.serviceInfo = data;
      const tempArray: ImageSliderModel[] = [];
      for (let index = 0; index < data?.secondaryImageModel?.length; index++) {
        const element = data.secondaryImageModel[index];
       tempArray.push({
          alt: data.name,
          image: element.large,
          thumbImage: element.large,
          title: ''
        })
      }
      this.imageData = [...tempArray];
      this.setSEOData();
    });

  }


  setSEOData(): void {
    const merchantDetails = this.merchantService.merchant;
   
    let shortBio =this.productService.serviceInfo.shortBio ? this.productService.serviceInfo.shortBio : "";

    let aboutDescription = `${this.productService.serviceInfo.name}, ${shortBio} ,Services of ${merchantDetails.name}, Services of ${merchantDetails.name} in ${merchantDetails.locality}, Services of ${merchantDetails.name} in ${merchantDetails.city}, ${merchantDetails.shortBio}`;

    this.titleService.setTitle(aboutDescription);

    this.metaService.addTag({name: 'name', content: aboutDescription});
    this.metaService.addTag({name: 'description', content: aboutDescription});
    this.metaService.addTag({name: 'image', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'logo', content: merchantDetails.logoImage.small})
    this.metaService.addTag({name: 'keywords', content: aboutDescription + ", " + merchantDetails.keywordList})


    this.metaService.addTag({name: 'twitter:card', content: 'product'});
    this.metaService.addTag({name: 'twitter:title', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:description', content: aboutDescription});
    this.metaService.addTag({name: 'twitter:image', content: merchantDetails.logoImage.small})


    let serviceUrl = this.productService.serviceInfo.name.replace(" ", "-");
    serviceUrl = serviceUrl.replace("products", "services");

    const routeParams = this.route.snapshot.paramMap;
    let index = Number(routeParams.get('Index'));
    //OG GRAPH DATA
    this.metaService.addTag({name: 'og:type', content: 'article'});
    this.metaService.addTag({name: 'og:site_name', content: merchantDetails.name});
    this.metaService.addTag({name: 'og:url', content: merchantDetails.url + "/services/" + serviceUrl + "/" + index});
    this.metaService.addTag({name: 'og:description', content: aboutDescription});
    this.metaService.addTag({name: 'og:image', content: merchantDetails.logoImage.small})
  }

}
