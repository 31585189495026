<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 itemprop="name">{{productService?.serviceInfo?.name}}</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService?.merchant?.name}}" itemprop="url">Home</a></li>
                <li class="active">{{productService?.serviceInfo?.name}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Services Details -->
<div class="services-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul *ngFor="let catItem of categoryService?.productCategories">
                            <li>
                                <a routerLink="/{{catItem.slug.replace('category', 'department')}}" title="{{catItem.name}}" itemprop="url">
                                   {{catItem.name}}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget tags">
                        <h3>Tags</h3>

                        <ul>
                            <li *ngFor="let keyItem of merchantService?.merchant?.keywords">
                                <a routerLink="/search/{{keyItem.replaceAll(' ','-')}}/1" title="{{keyItem}}" itemprop="url">{{keyItem}}</a>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="services-details-img">
                        <img itemprop="image" *ngIf="!productService?.serviceInfo?.primaryImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/large/5fff3d958d5dbc10304de1bb.png" alt="{{productService?.serviceInfo?.name}}">
                        <img itemprop="image" *ngIf="productService?.serviceInfo?.primaryImage" src="{{productService?.serviceInfo?.primaryImage?.large}}" alt="{{productService?.serviceInfo?.name}}">
                    </div>

                    <div class="services-top-content">
                        <div class="news-content">
                            <h3 itemprop="name">{{productService?.serviceInfo?.name}}</h3>
                            <span>{{productService?.serviceInfo?.category}}</span>
                            <p style="text-align: justify;" itemprop="description">{{productService?.serviceInfo?.shortBio}}</p>
                            <p style="text-align: justify;" itemprop="description" [innerHTML]="productService?.serviceInfo?.description | keepHtml">Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Details -->

<!-- End Doctor Details -->
<app-subscribers></app-subscribers>
<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->
