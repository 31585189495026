<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Home</a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Contact Area -->
<section class="contact-area ptb-100" *ngIf="merchantService.merchant.username === 'kjsdentalhospital'" >
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="contact-form">
                    <div class="contact-title">
                        <h2>Drop us message for any query</h2>
                    </div>

                    <form [formGroup]="contactForm" (ngSubmit)="contactUs()">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>First Name*</label>
                                    <input formControlName="firstName" type="text" name="name" id="name" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input formControlName="lastName" type="text" name="msg_subject" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Email Address*</label>
                                    <input formControlName="email" type="email" name="email" id="email" class="form-control">
                                </div>
                            </div>

                            

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Phone*</label>
                                    <input formControlName="phone" type="text" name="phone_number" id="phone_number" required class="form-control">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Message*</label>
                                    <textarea formControlName="messageText" name="message" class="form-control" id="message" cols="30" rows="10"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn btn-two">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            
        </div>
        <div class="row align-items-center" >
            <div class="col-lg-6">
                <div class="address-and-week">
                    <p>You can reach us using the information mentioned below</p>
                
                    <div class="address-week">
                        <ul class="address">
                            <li>
                                <i class="flaticon-telephone-1"></i>
                                <span>Call: </span>
                                <a href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}" title="Call us" itemprop="telephone"> {{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                            </li>
                            <li>
                                <i class="flaticon-arroba"></i>
                                <span>Email: </span>
                                <a href="mailto:{{merchantService.merchant.email}}" title="Email us" itemprop="email">{{merchantService.merchant.email}}</a>
                            </li>
                            <li>
                                <i class="flaticon-address-1"></i>
                                <span>Address:</span>
                                KJS Dental Hospital, 2nd Floor, Maruti Rawtani Plaza, Opp Poulomi Hospital, A.S. Rao Nagar, Secunderabad, Telangana, 500062
                            </li>
                        </ul>

                       
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="address-and-week">
                    <p>You can reach us using the information mentioned below</p>
                
                    <div class="address-week">
                        <ul class="address">
                            <li>
                                <i class="flaticon-telephone-1"></i>
                                <span>Call: </span>
                                <a href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}" title="Call us" itemprop="telephone"> {{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                            </li>
                            <li>
                                <i class="flaticon-arroba"></i>
                                <span>Email: </span>
                                <a href="mailto:{{merchantService.merchant.email}}" title="Email us" itemprop="email">{{merchantService.merchant.email}}</a>
                            </li>
                            <li>
                                <i class="flaticon-address-1"></i>
                                <span>Address:</span>
                                KJS Dental Hospital, 4-319, KJS Plaza, Below Canara Bank Main road, Balajinagar, Secunderabad, Telangana 500087
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

          
            
        </div>

        

    </div>
</section>

<section class="contact-area ptb-100" *ngIf="merchantService.merchant.username != 'kjsdentalhospital'" >
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="contact-form">
                    <div class="contact-title">
                        <h2>Drop us message for any query</h2>
                    </div>

                    <form [formGroup]="contactForm" (ngSubmit)="contactUs()">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>First Name*</label>
                                    <input formControlName="firstName" type="text" name="name" id="name" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Last Name</label>
                                    <input formControlName="lastName" type="text" name="msg_subject" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Email Address*</label>
                                    <input formControlName="email" type="email" name="email" id="email" class="form-control">
                                </div>
                            </div>

                            

                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group">
                                    <label>Phone*</label>
                                    <input formControlName="phone" type="text" name="phone_number" id="phone_number" required class="form-control">
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Message*</label>
                                    <textarea formControlName="messageText" name="message" class="form-control" id="message" cols="30" rows="10"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn btn-two">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

               <div class="col-lg-4">
                <div class="address-and-week">
                    <p>You can reach us using the information mentioned below</p>
                
                    <div class="address-week">
                        <ul class="address">
                            <li>
                                <i class="flaticon-telephone-1"></i>
                                <span>Call: </span>
                                <a href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}" title="Call us" itemprop="telephone"> {{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                            </li>
                            <li>
                                <i class="flaticon-arroba"></i>
                                <span>Email: </span>
                                <a href="mailto:{{merchantService.merchant.email}}" title="Email us" itemprop="email">{{merchantService.merchant.email}}</a>
                            </li>
                            <li>
                                <i class="flaticon-address-1"></i>
                                <span>Address:</span>
                               {{merchantService.merchant.streetAddress}} {{merchantService.merchant.locality}} {{merchantService.merchant.city}} {{merchantService.merchant.country}} {{merchantService.merchant.pinCode}}
                            </li>
                        </ul>
                         <ul class="week">
                            <li>
                                Sunday
                                <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.sunday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                            </li>
                            <li>
                                Monday
                                <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.monday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                            </li>
                            <li>
                                Tuesday
                                <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.tuesday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                            </li>
                            <li>
                                Wednesday
                                <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.wednesday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                            </li>
                            <li>
                                Thursday
                                <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.thursday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                            </li>
                            <li>
                                Friday
                                <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.friday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                            </li>
                            <li>
                                Saturday
                                <span *ngIf="merchantService.merchant.bizHours">{{merchantService.merchant.bizHours.saturday}}</span>
                                <span *ngIf="!merchantService.merchant.bizHours">Off</span>
                            </li>

                        </ul>

                       
                    </div>
                </div>
            </div>

            
        </div>
        <div class="row align-items-center" >
            <div class="col-lg-6">
                <div class="address-and-week">
                    <p>You can reach us using the information mentioned below</p>
                
                    <div class="address-week">
                        <ul class="address">
                            <li>
                                <i class="flaticon-telephone-1"></i>
                                <span>Call: </span>
                                <a href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}" title="Call us" itemprop="telephone"> {{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                            </li>
                            <li>
                                <i class="flaticon-arroba"></i>
                                <span>Email: </span>
                                <a href="mailto:{{merchantService.merchant.email}}" title="Email us" itemprop="email">{{merchantService.merchant.email}}</a>
                            </li>
                            <li>
                                <i class="flaticon-address-1"></i>
                                <span>Address:</span>
                                KJS Dental Hospital, 2nd Floor, Maruti Rawtani Plaza, Opp Poulomi Hospital, A.S. Rao Nagar, Secunderabad, Telangana, 500062
                            </li>
                        </ul>

                       
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="address-and-week">
                    <p>You can reach us using the information mentioned below</p>
                
                    <div class="address-week">
                        <ul class="address">
                            <li>
                                <i class="flaticon-telephone-1"></i>
                                <span>Call: </span>
                                <a href="tel:{{merchantService.merchant.countryCode}}{{merchantService.merchant.mobileNumber}}" title="Call us" itemprop="telephone"> {{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}</a>
                            </li>
                            <li>
                                <i class="flaticon-arroba"></i>
                                <span>Email: </span>
                                <a href="mailto:{{merchantService.merchant.email}}" title="Email us" itemprop="email">{{merchantService.merchant.email}}</a>
                            </li>
                            <li>
                                <i class="flaticon-address-1"></i>
                                <span>Address:</span>
                                KJS Dental Hospital, 4-319, KJS Plaza, Below Canara Bank Main road, Balajinagar, Secunderabad, Telangana 500087
                            </li>
                        </ul>

                    </div>
                </div>
            </div>

          
            
        </div>

        

    </div>



</section>
<!-- End Contact Area -->

<agm-map [zoom]="12" *ngIf="merchantService.merchant.username === 'kjsdentalhospital'" [latitude]="lat" [longitude]="lng" >
    <agm-marker [latitude]="17.477150" [longitude]="78.559150">
      <agm-info-window>KJS Dental Hospital, A S Rao Nagar</agm-info-window>
    </agm-marker>

    <agm-marker [latitude]="17.512774" [longitude]="78.560921">
        <agm-info-window>KJS Dental Hospital, Balaji Nagar</agm-info-window>
      </agm-marker>
  </agm-map>

  <agm-map *ngIf="merchantService.merchant.username != 'kjsdentalhospital'" [zoom]="13" [usePanning]="true"  [latitude]="lat" [longitude]="lng" >
    <agm-marker [latitude]="lat" [longitude]="lng">
      <agm-info-window>{{merchantService.merchant.Name}}</agm-info-window>
      </agm-marker>
    </agm-map>

<!-- End Map Area -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->