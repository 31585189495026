<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Testimonials</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Testimonials</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Testimonial -->
<section class="testimonial-area testimonial-area-two pt-100 pb-70" *ngIf="testimonialService?.testimonials?.length > 0">
    <div class="container">
        <div class="section-title">
            <span>Testimonial</span>
            <h2>What do our patients say?</h2>
        </div>

        <div class="row">
            <div class="col-12" *ngFor="let testItem of testimonialService.testimonials">
                <div class="testimonial-content">
                    <i class="flaticon-straight-quotes"></i>
                    <h1>{{testItem.title}}</h1>
                    <p>“{{testItem.description}}”</p>
                    
                    <h3>{{testItem.name}}</h3>
                </div>
                <br/><br/>
            </div>
            
           
        </div>
    </div>
</section>
<!-- End Testimonial -->

<section class="testimonial-area testimonial-area-two pt-100 pb-70" *ngIf="testimonialService?.testimonials?.length == 0">
    <div class="container">
        <div class="section-title">
            <h2>Keep watching this space to hear more from our patients</h2>
        </div>

        
    </div>
</section>

<!-- Subscribe -->
<app-subscribers></app-subscribers>
<!-- End Subscribe -->

<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->