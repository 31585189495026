import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { backgroundImage } from '../../models/merchant/bgImage.models';
import { Merchant } from '../../models/merchant/merchant.models';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  merchantDataEvt = new Subject();
  merchant: Merchant | any;
  backgroundImages: backgroundImage[] | any;
  userName = '';

  constructor(private http: HttpClient, private cookieService: CookieService) {

  }

  setMerchantData(mData: any): void {
    this.merchant = mData;
    this.merchantDataEvt.next(mData);
  }

  setUserName(uName: string): void {
    this.userName = uName;
  }
  getUserName(): string {
    return this.userName;
  }
  getMerchantData(): Merchant {
    return this.merchant;
  }

  getMechangeDetailsObserver(): Observable<any> {
    const username = this.cookieService.get('username');
    const merchantDetailsEndPoint = environment.apiUrl + '/api/Merchants/' + username;
    return this.http.get<Merchant>(merchantDetailsEndPoint);
  }
  // tslint:disable-next-line: typedef

  getBackgroundImage(): Observable<any> {
    const backgroundImagesEndpoint = environment.apiUrl + '/api/Images/background/' + this.userName;
    return this.http.get<backgroundImage[]>(backgroundImagesEndpoint)
  }
}
