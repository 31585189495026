<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Departments</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Home</a></li>
                <li class="active">Our Departments</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Services -->
<section class="services-area services-area-two bg-t ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our departments</span>
            <h2>All the services that we provide to our patients in the field of treatment</h2>
        </div>
        
        <div class="row">
            <div class="col-lg-3 col-sm-6" *ngFor="let catItem of categoryService.productCategories">
                <div class="single-services">
                    <div class="services-content">
                        <h3><a routerLink="/department/{{catItem.name.replaceAll(' ', '-')}}/{{catItem._id}}" title="{{catItem.name}}" itemprop="url">{{catItem.name}}</a></h3>
                       
                    </div>
                </div>
            </div>
        
            

           
        </div>
    </div>
</section>
<!-- End Services -->



<!-- End Doctor Details -->
<app-subscribers></app-subscribers>
<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->