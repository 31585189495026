import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { subscriber } from '../../models/merchant/subscriber.models';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  constructor(private http: HttpClient, 
    private toastr: ToastrService,
    private cookieService: CookieService) { }

  subscribeUser(emailId: string){
    const subscriberEndpoint = environment.apiUrl + "/api/Internal/subscribers";
    let usernameDetails = this.cookieService.get("username");
    const subscriberModel: subscriber = {
      username: usernameDetails,
      email: emailId
    };
    isSubscribed : false;
    this.http.post(subscriberEndpoint, subscriberModel, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: 'You already have a existing subscription' })
      })
    ).subscribe((d: any) => {
      if(d == null)
      {
        this.toastr.success("Thank you for subscribing");
      }
      else if (d.err) {
        this.toastr.success("You already have a existing subscription");
      }
    
    });

  }
}
