<!-- Navbar -->
<app-navbar-style-two></app-navbar-style-two>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Doctors</h2>
            <ul>
                <li><a routerLink="/" title="{{merchantService.merchant.name}}" itemprop="url">Home</a></li>
                <li class="active">Our Doctors</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Doctors -->
<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our specialists</span>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6" *ngFor="let pdItem of productService.products">
                <a routerLink="/{{pdItem.slug.replace('products','doctors')}}" title="{{pdItem.name}}" itemprop="url">
                    <div class="single-doctors">
                        <div class="doctors-img">
                            <img itemprop="image" *ngIf="!pdItem.primaryImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{pdItem.name}}">
                            <img *ngIf="pdItem.primaryImage" src="{{pdItem.primaryImage.small}}" alt="{{pdItem.name}}">
                        </div>
                        <div class="doctors-content">
                            <span itemprop="category">{{pdItem.category}}</span>
                            <h3 itemprop="name">{{pdItem.name}}</h3>
                        </div>
                    </div>
                
                </a>
                
            </div>

            <br/>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a itemprop="url" title="Previous page" routerLink="{{productService.prevLink}}"  class="prev page-numbers">
                        <i class="bx bx-chevron-left"></i>
                    </a>

                    <a itemprop="url" title="Next page" routerLink="{{productService.nextLink}}" class="next page-numbers">
                        <i class="bx bx-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Doctors -->

<app-subscribers></app-subscribers>
<!-- Footer -->
<app-footer-style-one></app-footer-style-one>
<!-- End Footer -->