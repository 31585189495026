import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Testimony } from '../../models/testimonials/testimony.models';

@Injectable({
  providedIn: 'root'
})
export class TestimonialsService {
  testimonials : Array<Testimony> | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getTestimonails()
  {
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const testimonyEndPoint =  environment.apiUrl + '/api/Testimonys/'+ username;
      this.http.get<Testimony[]>(testimonyEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : Testimony[] |any) => {
        this.testimonials = data;
      });
    }
  }
}
